/*
 * @Description: 
 * @Author: 
 * @Date: 2023-03-15 15:30:18
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-19 17:49:23
 */
// 液源管理中的网络请求

import {request} from "./request";


// 获取液源的列表
export function getCategoriesListRequest(params) {
  return request({
    url: '/categories',
    params
  })
}

// 添加分类
export function addCategoryRequest(params) {
  return request({
    url: '/categories',
    method: 'post',
    data: {...params}
  })
}

// 查询贸易企业
export function getFactoryRequest() {
  return request ({
    url: '/trade/factory/list',
    method: 'post',
  })
}

export function addFactoryRequest(params){
  return request ({
    url: '/trade/wechat/lngSource/saveSource',
    method: 'post',
    data: params
  })
}

export function editFactoryRequest(params){
  return request ({
    url: '/trade/lngSource/updateSource',
    method: 'post',
    data: params
  })
}

// 根据 id 查询分类
export function getCategoryByIdRequest(id) {
  return request({
    url: `/categories/${id}`
  })
}

// 根据 id 编辑提交分类
export function editCategoryByIdRequest(params) {
  return request({
    url: `/categories/${params.cat_id}`,
    method: 'put',
    data: {
      cat_name: params.cat_name
    }
  })
}

// 根据 id 编辑提交分类
export function removeCategoryByIdRequest(id) {
  return request({
    url: `/categories/${id}`,
    method: 'delete'
  })
}

// 分类参数---------------------------
// 获取参数列表
export function getCategoryParamsRequest(id, sel) {
  return request({
    url: `/categories/${id}/attributes`,
    params: {
      sel
    }
  })
}

// 添加动态参数或者静态属性
export function addParamRequest(params) {
  return request({
    url: `/categories/${params.id}/attributes`,
    method: 'post',
    data: {
      attr_name: params.attr_name,
      attr_sel: params.attr_sel
    }
  })
}

// 根据 ID 查询参数
export function getParamByIdRequest(data) {
  return request({
    url: `/categories/${data.id}/attributes/${data.attrId}`,
    params: {
      attr_sel: data.attr_sel
    }
  })
}

// 根据 ID 修改参数
export function editParamByIdRequest(data) {
  return request({
    url: `/categories/${data.id}/attributes/${data.attrId}`,
    method: 'put',
    data: {
      attr_name: data.attr_name,
      attr_sel: data.attr_sel
    }
  })
}

// 根据 ID 删除参数
export function removeParamByIdRequest(data) {
  return request({
    url: `/categories/${data.id}/attributes/${data.attrId}`,
    method: 'delete'
  })
}

// 获取液源列表数据
export function getGoodsListRequest(params) {
  return request({
    url: '/trade/lngSource/sourceList',
    params
  })
}
// 

export function getGoodsListOneRequest(params){
  return request ({
    url: '/trade/wechat/lngSource/buySourceList',
    params
  })
}

// 删除液源
export function removeGoodsRequest(params){
  return request ({
    url: `/trade/lngSource/${params.id}`,
    method: 'delete'
  })
}

// 获取购液企业的贸易企业信息
export function getEnterpriseRequest(params){
  return request({
    url: '/trade/trade/emption_enterprise/tardList',
    params
  })
}

// 获取价格记录列表
export function getGoodsPriceListRequest(params){
  return request({
    url: '/trade/lngSource/priceList',
    params
  })
}

// 根据 ID 删除商品
export function removeGoodsByIdRequest(id) {
  return request({
    url: `/goods/${id}`,
    method: 'delete'
  })
}

// 添加商品
export function addGoodsRequest(data) {
  return request({
    url: `/goods`,
    method: 'post',
    data
  })
}

